import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import html_23603 from "../views/html_23603.vue";
import html_23602 from "../views/html_23602.vue";
import html_23601 from "../views/html_23601.vue";
import html_23501 from "../views/html_23501.vue";
import index from "../views/home.vue";
const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: index,
  },
  {
    path: "/23603",
    name: "23603",
    component: html_23603,
  },
  {
    path: "/23602",
    name: "23602",
    component: html_23602,
  },
  {
    path: "/23601",
    name: "23601",
    component: html_23601,
  },
  {
    path: "/23501",
    name: "23501",
    component: html_23501,
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
