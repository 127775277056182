<template>
  <div><router-view /></div>
</template>

<script>
export default {
  name: "VuehtApp",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
