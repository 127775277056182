<template>
  <div>
    <h1>关于Nginx学习</h1>
    <p>以下是一些关于Nginx学习的重要信息：</p>

    <h2>什么是Nginx？</h2>
    <p>
      Nginx是一款高性能的开源Web服务器和反向代理服务器。它以其出色的性能、高可靠性和低资源消耗而闻名。Nginx还可以作为负载均衡器、HTTP缓存和反向代理服务器等多种用途。
    </p>

    <h2>为什么学习Nginx？</h2>
    <div>
      学习Nginx有以下几个重要原因：
      <ul>
        <li>高性能：Nginx以其并发处理能力和低内存消耗而受到广泛认可。</li>
        <li>可扩展性：Nginx可以处理大量并发连接，并轻松应对高负载情况。</li>
        <li>
          反向代理和负载均衡：Nginx可以用作反向代理服务器和负载均衡器，提高应用程序的可用性和性能。
        </li>
        <li>
          安全性：Nginx提供了一些重要的安全功能，如SSL/TLS支持和基本的访问控制。
        </li>
      </ul>
    </div>

    <h2>学习资源</h2>
    <p>以下是一些学习Nginx的资源推荐：</p>
    <ul>
      <li>
        官方文档：<a href="https://nginx.org/en/docs/"
          >https://nginx.org/en/docs/</a
        >
      </li>
      <li>
        Nginx教程：<a href="https://www.nginx.com/resources/library/"
          >https://www.nginx.com/resources/library/</a
        >
      </li>
      <li>
        Nginx中文文档：<a href="https://www.nginx.cn/doc/"
          >https://www.nginx.cn/doc/</a
        >
      </li>
      <li>
        Nginx入门指南：<a
          href="https://www.nginx.com/blog/nginx-beginners-guide/"
          >https://www.nginx.com/blog/nginx-beginners-guide/</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NginxLearning",
};
</script>

<style scoped>
h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 10px;
}

ul {
  margin-top: 0;
  margin-bottom: 10px;
}

li {
  margin-bottom: 5px;
}

pre {
  background-color: #f5f5f5;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

code {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
}
</style>
