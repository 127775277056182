import { render, staticRenderFns } from "./html_23602.vue?vue&type=template&id=5ce8e80c&scoped=true"
import script from "./html_23602.vue?vue&type=script&setup=true&lang=js"
export * from "./html_23602.vue?vue&type=script&setup=true&lang=js"
import style0 from "./html_23602.vue?vue&type=style&index=0&id=5ce8e80c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce8e80c",
  null
  
)

export default component.exports